import SideBar from "./Body/SideBar/SideBar";
import classes from "./Teams.module.scss"
import React from "react";
import {useOutletContext} from "react-router-dom";
import {Outlet} from 'react-router-dom';
import Wrapper from "./Body/Wrapper/Wrapper";
import SideDrawer from "./Body/SideDrawer/SideDrawer";
import {connect} from "react-redux";
import Header from "./Header/Header";

const Teams = ({color}) => {
  const outletContext = useOutletContext();
  const [open, setOpen] = React.useState(true);
  return (
    <div className={classes.TeamsContainer}>
      <Header toggleMenu={outletContext.toggleMenu}/>
      <SideBar open={open} setOpen={setOpen} color={color}/>
      <SideDrawer color={color}/>
      <Wrapper sideBarOpen={open}>
        <Outlet/>
      </Wrapper>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    color: state.home.themeColor,
  };
};

export default connect(mapStateToProps)(Teams);
