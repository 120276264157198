import React from 'react';
import classes from './Contact.module.scss';
import {MdKeyboardArrowRight} from 'react-icons/md';

const Contact = props => {
  const getInitials = name => {
    const splitName = name.split(' ');
    let initials = splitName[0].substring(0, 1).toUpperCase();
    if (splitName[1]) {
      initials += splitName[1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  return (
    <div className={classes.Contact} onClick={props.onClick} key={props.index}>
      <div className={classes.LeftContainer}>
        <div className={classes.InitialsContainer}>
          <p style={{color:props.color}}>{getInitials(props.contact.name)}</p>
        </div>
        <div className={classes.DetailContainer}>
          <p>{props.contact.name}</p>
          <p>{props.contact.contactNo}</p>
        </div>
      </div>
      {/* <MdKeyboardArrowRight color="#4c4c4c" size={28} /> */}
      <MdKeyboardArrowRight color="#555555" size={28} />
    </div>
  );
};

export default Contact;
