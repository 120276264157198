import React from 'react'
import classes from "./TryAnotherWayPopup.module.scss";
import {MdOutlineClose} from "react-icons/md";

const TryAnotherWaPopup = ({onClose}) => {
  return (
    <div className={classes.TryAnotherWayPopup}>
      <div className={classes.CloseButtonContainer}>
        <div onClick={onClose}>
          <MdOutlineClose size={22} color="#C5C5C5" />
        </div>
      </div>
      <p className={classes.ConfirmationText}>Contact Us</p>
      <p className={classes.InfoText}>
        This profile will be unavailable on your profile list
      </p>
    </div>
  )
}

export default TryAnotherWaPopup
