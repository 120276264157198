import {MdOutlineClose} from "react-icons/md";
import React from "react";
import classes from "./LogOutConfirmation.module.scss";
import {signOut} from "../../../../store/actions";
import {connect} from "react-redux";
import {createGradient} from "../../../../shared/Constants";

const LogOutConfirmation = props => {
  return (
    <div className={classes.LogoutConfirmation}>
      <div className={classes.CloseButtonContainer}>
        <div onClick={props.onClose}>
          <MdOutlineClose size={22} color="#C5C5C5" />
        </div>
      </div>
      <p className={classes.ConfirmationText}>
        Logout Confirmation
      </p>
      <p className={classes.InfoText}>
        Are you sure you want to logout?
      </p>
      <button style={{background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color,50)} 100%)`}}
              className={classes.LogoutButton}
              onClick={props.onSignOut}>
        Yes, Logout
      </button>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onSignOut: () => dispatch(signOut()),
  };
};

export default connect( null,mapDispatchToProps)(LogOutConfirmation)
