export const GET_ALL_CONTACTS_SUCCESS = 'GET_ALL_CONTACTS_SUCCESS';

export const GET_CONTACT_START = 'GET_CONTACT_START';

export const GET_CONTACT_END = 'GET_CONTACT_END';

export const TOGGLE_EXPORT_IMPORT_DROPDOWN = 'TOGGLE_EXPORT_IMPORT_DROPDOWN';

export const ADD_NEW_CONTACT_START = 'ADD_NEW_CONTACT_START';

export const ADD_NEW_CONTACT_END  = 'ADD_NEW_CONTACT_END';

export const UPDATE_CONTACT_START = 'UPDATE_CONTACT_START';

export const CHANGE_SELECTED_ID = 'CHANGE_SELECTED_ID';

export const UPDATE_CONTACT_END = 'UPDATE_CONTACT_END';

export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
