import * as actionTypes from '../actions/account/actionTypes';

const initialState = {
  loading: false,
};

const updateAccountStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const updateAccountEnd = (state, action) => {
  return {
    ...state,
    loading: false,
  };
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ACCOUNT_DETAILS_START:
      return updateAccountStart(state, action);
    case actionTypes.UPDATE_ACCOUNT_DETAILS_END:
      return updateAccountEnd(state, action);
    default:
      return state;
  }
};

export default reducer;
