import React from 'react';
import classes from './ExitConfirmation.module.scss';
import {MdOutlineClose} from 'react-icons/md';
import {createGradient} from "../../../shared/Constants";

const ExitConfirmation = props => {
  return (
    <div className={classes.ExitConfirmation}>
      <div className={classes.CloseButtonContainer}>
        <div onClick={props.onClose}>
          <MdOutlineClose size={22} color="#C5C5C5" />
        </div>
      </div>
      <p className={classes.ConfirmationText}>Go back?</p>
      <p className={classes.InfoText}>Any changes you made will be discarded</p>
      <button
        style={{background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color,50)} 100%)`}}
        className={classes.ContinueButton}
        onClick={props.onConfirmation}>
        Confirm
      </button>
    </div>
  );
};

export default ExitConfirmation;
