import classes from "./SecondStep.module.scss";
import {RiMailUnreadFill} from "react-icons/ri";
import React, {useRef, useState} from "react";

const SecondStep = ({setOtp,email}) => {
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const boxes = useRef([]);

  const handleInput = (event, index) => {
    const input = event.target.value;
    if (!/^[0-9a-zA-Z]*$/.test(input)) return; // Only allow alphanumeric characters

    setCode(prevCode => {
      const newCode = [...prevCode];
      newCode[index] = input;
      setOtp(newCode.join(''));
      return newCode;
    });

    if (input !== '') { // Move focus to the next box when input is entered
      boxes.current[index + 1]?.focus();
    }
  };


  const handleKeyDown = (event, index) => {
    if (event.key === 'ArrowLeft') {
      boxes.current[index - 1]?.focus();
    } else if (event.key === 'ArrowRight') {
      boxes.current[index + 1]?.focus();
    } else if (event.key === 'Backspace') {
      if (code[index] === '') { // Skip empty cells
        let i = index - 1;
        while (i >= 0 && code[i] === '') {
          i--;
        }
        boxes.current[i]?.focus(); // Move to last filled box if it exists
      } else { // Delete number and move focus to previous box
        setCode(prevCode => {
          const newCode = [...prevCode];
          newCode[index] = '';
          return newCode;
        });
        boxes.current[index - 1]?.focus();
      }
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text/plain');
    if (/^[0-9a-zA-Z]*$/.test(pasteData)) {
      const newCode = pasteData.split('').slice(0, 6);
      setCode(newCode.concat(['', '', '', '', '', '']).slice(0, 6));
      boxes.current[0]?.focus();
      setOtp(newCode.join(''));
    }
  };

  return (
    <div className={classes.SecondStep}>
      <div>
        <h1>Verify Your Email</h1>
        <div className={classes.IconWrapper}>
          <RiMailUnreadFill className={classes.Icon}/>
        </div>

        <p className={classes.Paragraph}>
          Please Enter The 6 Digit
          <br/>
          Verification Code sent to
          <br/>
          <span>{email}</span>
        </p>
      </div>

      <div className={classes.ValidationCodeInput}>
        {code.map((digit, index) => (
          <input
            key={index}
            type="text"
            className={classes.CodeBox}
            pattern="\d*"
            maxLength="1"
            value={code[index] || ''}
            onChange={event => handleInput(event, index)}
            onPaste={handlePaste}
            onKeyDown={event => handleKeyDown(event, index)}
            ref={ref => boxes.current[index] = ref}
          />
        ))}
      </div>

      <span className={classes.AnotherWay}>Resend Code</span>
    </div>
  )
}

export default SecondStep;
