export const CHANGE_EXPAND = 'CHANGE_EXPAND';

export const GET_ALL_COMPANY_USERS_START = 'GET_ALL_COMPANY_USERS_START';
export const GET_ALL_COMPANY_USERS = 'GET_ALL_COMPANY_USERS';

export const ADD_NEW_TEAM_USER_START = 'ADD_NEW_TEAM_USER_START';
export const ADD_NEW_TEAM_USER_SUCCESS = 'ADD_NEW_TEAM_USER_SUCCESS';
export const ADD_NEW_TEAM_USER_END = 'ADD_NEW_TEAM_USER_END';

export const UPDATE_COMPANY_USER_START = 'UPDATE_COMPANY_USER_START';
export const UPDATE_COMPANY_USER_SUCCESS = 'UPDATE_COMPANY_USER_SUCCESS';
export const UPDATE_COMPANY_USER_END = 'UPDATE_COMPANY_USER_END';

export const GET_ALL_PROFILES_START = 'GET_ALL_PROFILES_START';
export const GET_ALL_TEAM_USERS_PROFILES = 'GET_ALL_TEAM_USERS_PROFILES';
export const GET_ALL_PROFILES_END = 'GET_ALL_PROFILES_END';

export const UPDATE_TEAMS_COMPANY_DETAILS_START = 'UPDATE_TEAMS_COMPANY_DETAILS_START';
export const UPDATE_TEAMS_COMPANY_DETAILS_END = 'UPDATE_TEAMS_COMPANY_DETAILS_END';

export const DELETE_TEAM_USER_START = 'DELETE_TEAM_USER_START';
export const DELETE_TEAM_USER_END = 'DELETE_TEAM_USER_END';

export const UPDATE_TEAMS_BRANDING_DETAILS_START = 'UPDATE_TEAMS_BRANDING_DETAILS_START';
export const UPDATE_TEAMS_BRANDING_DETAILS_END = 'UPDATE_TEAMS_BRANDING_DETAILS_END';

export const CHANGE_BRANDING_IMAGE = 'CHANGE_BRANDING_IMAGE';
