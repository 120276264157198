import * as actionTypes from '../actions/contact/actionTypes';

const initialState = {
  contacts: [],
  exportImportDropdownVisible: false,
  loading: false,
  selectedId:null,
};

const toggleExportImportDropdown = (state, action) => {
  return {
    ...state,
    exportImportDropdownVisible: !state.exportImportDropdownVisible,
  };
};

const getAllContactsSuccess = (state, action) => {
  return {
    ...state,
    contacts: action.contacts,
  };
};

const changeSelectedId = (state, action) => {
  return {
    ...state,
    selectedId:action.payload,
  };
};

const addNewContactStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const addNewContactEnd = (state, action) => {
  return {
    ...state,
    loading: false,
  };
};

const getContactStart = (state, action) => {
  return{
    ...state,
    loading: true,
  }
}

const getContactEnd = (state, action) => {
  return{
    ...state,
    loading: false,
  }
}

const updateContactStart = (state, action) => {
  return{
    ...state,
    loading: true,
  }
}

const updateContactEnd = (state, action) => {
  return{
    ...state,
    loading: false,
  }
}


const deleteContactSuccess = (state, action) => {
  return{
    ...state,
    contacts: state.contacts.filter((contact) => contact.id !== action.contactId)
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_CONTACTS_SUCCESS:
      return getAllContactsSuccess(state, action);
    case actionTypes.TOGGLE_EXPORT_IMPORT_DROPDOWN:
      return toggleExportImportDropdown(state, action);
    case actionTypes.ADD_NEW_CONTACT_START:
      return addNewContactStart(state, action);
    case actionTypes.ADD_NEW_CONTACT_END:
      return addNewContactEnd(state, action);
    case actionTypes.GET_CONTACT_START:
      return getContactStart(state, action);
    case actionTypes.GET_CONTACT_END:
      return getContactEnd(state, action);
    case actionTypes.UPDATE_CONTACT_START:
      return updateContactStart(state, action);
    case actionTypes.UPDATE_CONTACT_END:
      return updateContactEnd(state, action);
    case actionTypes.DELETE_CONTACT_SUCCESS:
      return deleteContactSuccess(state, action);
    case actionTypes.CHANGE_SELECTED_ID:
      return changeSelectedId(state, action);
    default:
      return state;
  }
};

export default reducer;
