import React from 'react';
import classes from './StatusChangeConfirmation.module.scss';
import {MdOutlineClose} from 'react-icons/md';
import {connect} from 'react-redux';
import * as actions from '../../../../../store/actions';
import {createGradient} from "../../../../../shared/Constants";

const ShareableProfileDeactivation = props => {
  const vcardActive = props.vcardStatus === 'ACTIVE';

  const onChangeHandler = () => {
    const newStatus = props.vcardStatus === 'ACTIVE' ? 'DEACTIVE' : 'ACTIVE';
    props.onChangeVcardStatus(props.userId, newStatus);
    props.onClose();
  };

  return (
    <div className={classes.StatusChangeConfirmation}>
      <div className={classes.CloseButtonContainer}>
        <div onClick={props.onClose}>
          <MdOutlineClose size={22} color="#C5C5C5" />
        </div>
      </div>
      <p className={classes.ConfirmationText}>
        Do you want to {vcardActive ? 'deactivate' : 'activate'} your
        <br />
        shareable profile?
      </p>
      <p className={classes.InfoText}>
        {vcardActive
          ? 'Shareable Link will not work if deactivated'
          : 'Shareable Link will be functional on activation'}
      </p>
      <button style={{background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color,50)} 100%)`}}
              className={classes.DeactivateButton}
              onClick={onChangeHandler}>
        {vcardActive ? 'Yes, Deactivate' : 'Activate'}
      </button>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userId: state.auth.user.id,
    vcardStatus: state.home.vcardStatus,
    color: state.home.themeColor,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onChangeVcardStatus: (userId, status) =>
      dispatch(actions.changeVcardStatus(userId, status)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShareableProfileDeactivation);
