import React from 'react';
import classes from './QRCodeopup.module.scss';
import {MdOutlineClose} from 'react-icons/md';
import {createGradient} from "../../../../../shared/Constants";
import { QRCode } from 'react-qrcode-logo';
import Logo from '../../../../../assets/images/Prime QR icon.jpg'
import {connect} from "react-redux";
import * as actions from "../../../../../store/actions";

const QRCodePopup = props => {

  const downloadQRCode = () => {
    const canvas = document.querySelector('canvas');
    const dataURL = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = 'qrcode.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  React.useEffect(() => {
    props.onGetLinkByUsername(props.user)
  }, []);

  return (
    <div className={classes.QRCodePopup}>
      <div className={classes.CloseButtonContainer}>
        <div onClick={props.onClose}>
          <MdOutlineClose size={22} color="#C5C5C5"/>
        </div>
      </div>
      <QRCode
        value={process.env.REACT_APP_SHARABLE_LINK+'@'+props.link}
        logoImage = {Logo}
        logoWidth={60}
        logoHeight={60}
        removeQrCodeBehindLogo={true}
        size={180}
        eyeRadius={[
          {
            outer: [8, 8, 8, 8],
            inner: [1, 1, 1, 1],
          },
          {
            outer: [8, 8, 8, 8],
            inner: [1, 1, 1, 1],
          },
          {
            outer: [8, 8, 8, 8],
            inner: [1, 1, 1, 1],
          },
        ]}
        eyeBallRadius={2}
        quietZone={3}
        ecLevel={"H"}
        enableCORS={true}
        logoPadding={1}
      />
      <button
        style={{background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color, 50)} 100%)`}}
        className={classes.ContinueButton}
        onClick={downloadQRCode}
      >
        Download
      </button>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    link: state.link.link,
    user: state.auth.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetLinkByUsername: (userName) => dispatch(actions.getLinkByUsername(userName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QRCodePopup);
