export const VISIBILITY = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
};

export const adjustColorBrightness = (color, value) => {
  // Parse the color string into RGB values
  const red = parseInt(color.substring(1, 3), 16);
  const green = parseInt(color.substring(3, 5), 16);
  const blue = parseInt(color.substring(5, 7), 16);

  // Adjust the brightness of the RGB values based on the value
  let newRed = red;
  let newGreen = green;
  let newBlue = blue;
  if (value > 0) {
    // Make the color darker by subtracting from the RGB values
    newRed = Math.max(0, red - value);
    newGreen = Math.max(0, green - value);
    newBlue = Math.max(0, blue - value);
  } else if (value < 0) {
    // Make the color lighter by adding to the RGB values
    newRed = Math.min(255, red - value);
    newGreen = Math.min(255, green - value);
    newBlue = Math.min(255, blue - value);
  }

  // Convert the adjusted RGB values back to a hex color string
  const newColor = "#" +
    newRed.toString(16).padStart(2, '0') +
    newGreen.toString(16).padStart(2, '0') +
    newBlue.toString(16).padStart(2, '0');

  // Return the adjusted color
  return newColor;
}


export const createGradient =(leftColor, darkness)=> {
  // Parse the left color string into RGB values
  const red = parseInt(leftColor.substring(1, 3), 16);
  const green = parseInt(leftColor.substring(3, 5), 16);
  const blue = parseInt(leftColor.substring(5, 7), 16);

  // Calculate the darker color by subtracting the darkness value from each RGB value
  const darkerRed = Math.max(red - darkness, 0);
  const darkerGreen = Math.max(green - darkness, 0);
  const darkerBlue = Math.max(blue - darkness, 0);

  // Convert the darker RGB values back to a hex color string
  // Create the gradient string using the left and darker colors
  // Return the gradient string
  return "#" +
    darkerRed.toString(16).padStart(2, '0') +
    darkerGreen.toString(16).padStart(2, '0') +
    darkerBlue.toString(16).padStart(2, '0');
}
