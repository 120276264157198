import classes from "./DeleteConfirmationPopup.module.scss";
import {MdOutlineClose} from "react-icons/md";
import React from "react";
import {createGradient} from "../../../../../../../../shared/Constants";
import {ClipLoader} from "react-spinners";

const DeleteConfirmationPopup = (props) => {
  return (
    <div className={classes.DeleteConfirmation}>
      <div className={classes.CloseButtonContainer}>
        <div onClick={props.onClose}>
          <MdOutlineClose size={22} color="#C5C5C5" />
        </div>
      </div>
      <p className={classes.ConfirmationText}>
        Delete Confirmation
      </p>
      <p className={classes.InfoText}>
        Are you sure you want to Delete this User?
      </p>
      <button
        className={classes.DeleteButton}
        style={{
        background:
          `linear-gradient(91.3deg, 
                ${props.color} 
                1.61%, 
                ${createGradient(props.color, 50)} 
                100%)`
      }}
        onClick={props.onDelete}
      >
        {props.loading === true ? (
          <div className={classes.SpinnerContainer}>
            <ClipLoader loading={true} color={"white"} size={18}/>
          </div>
        ) : (
          <p> Yes, Delete</p>
        )
        }
      </button>
    </div>
  );
};

export default DeleteConfirmationPopup;
