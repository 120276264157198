import React, {createRef, useState} from 'react';
import {MdOutlineClose} from 'react-icons/md';
import classes from './ImageCropper.module.scss';
import * as actions from '../../../store/actions';
import {connect} from 'react-redux';
import {createGradient} from "../../../shared/Constants";
import {useLocation} from "react-router-dom";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const ImageCropper = props => {
  const location = useLocation();
  const [zoom, setZoom] = useState(0.5);
  const cropperRef = createRef();

  const getCropData = () => {
    if (typeof cropperRef.current !== 'undefined') {
      return cropperRef.current.cropper.getCroppedCanvas().toDataURL();
    }
    return null;
  };

  const onDoneHandler = () => {
    const croppedImage = getCropData();

    if (croppedImage !== null) {
      if (location.pathname === '/home/teams/branding') {
        props.onChangeBrandingLogo(croppedImage);
      } else {
        props.onChangeProfilePicture(croppedImage);
      }
    }
    props.onClose();
  };

  return (
    <div className={classes.ImageCropper}>
      <div className={classes.CloseButtonContainer}>
        <button
          style={{background: `linear-gradient(91.3deg, ${props.color} 1.61%, ${createGradient(props.color, 80)} 100%)`}}
          className={classes.DoneButton}
          onClick={onDoneHandler}
        >
          Done
        </button>
        <div onClick={() => {
          props.onClose();
          props.onChangeProfilePicture('');
          props.onChangeBrandingLogo('');
        }}>
          <MdOutlineClose size={22} color="#C5C5C5"/>
        </div>
      </div>
      <div className={classes.CropContainer}>
        <Cropper
          ref={cropperRef}
          style={{height: "100%", width: "100%"}}
          zoomTo={zoom}
          aspectRatio={location.pathname === '/home/teams/company-details' ? NaN : 1}
          preview=".img-preview"
          src={location.pathname === '/home/teams/branding' ? props.brandingLogo : props.profilePicture.value}
          viewMode={1}
          onZoom={setZoom}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          guides={true}
          dragMode={location.pathname === '/home/teams/company-details' ? 'crop' : 'move'}
          cropBoxResizable={location.pathname === '/home/teams/company-details'}
          cropBoxMovable={location.pathname === '/home/teams/company-details'}

        />
      </div>
      <div className={classes.Controls}>
        <input
          type="range"
          value={zoom}
          min={0.5}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={e => {
            setZoom(parseFloat(e.target.value));
          }}
          className={classes.ZoomRange}
          style={{
            '--thumb-bg-color': props.color,
            WebkitSliderThumb: {
              background: 'var(--thumb-bg-color) !important'
            }
          }}
        />
        <style>
          {`
            input::-webkit-slider-thumb {
              background: ${props.color};
              border: 1px solid ${props.color};
            }
        `}
        </style>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    profilePicture: state.profile.profilePicture,
    color: state.home.themeColor,
    brandingLogo: state.teams.brandingLogo,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onChangeProfilePicture: image =>
      dispatch(actions.changeProfilePicture(image)),
    onChangeBrandingLogo: image =>
      dispatch(actions.changeBrandingLogo(image)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageCropper);
