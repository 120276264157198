import React, { useEffect, useState } from 'react';
import classes from './Categories.module.scss';
import * as actions from "../../../../../store/actions";
import { connect } from "react-redux";

const Categories = (props) => {
  const [selectedId, setSelectedId] = useState(props.selectedId);

  useEffect(() => {
    setSelectedId(props.selectedId);
  }, [props.selectedId]);

  useEffect(() => {
    props.onChangeSelectedId(selectedId);
  }, [selectedId]);

  return (
    <div className={classes.Categories}>
      <div
        className={selectedId === null ? classes.CategoryActive : classes.Category}
        onClick={() => {
          setSelectedId(null);
          props.getAll();
        }}
      >
        <p>All</p>
      </div>

      {props.profiles.names.map((name, index) => (
        <div
          className={props.profiles.ids[index] === selectedId ? classes.CategoryActive : classes.Category}
          key={props.profiles.ids[index]}
          onClick={() => {
            setSelectedId(props.profiles.ids[index]);
            props.setId(props.profiles.ids[index]);
          }}
        >
          <p>{name}</p>
        </div>
      ))}
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onChangeSelectedId: id => dispatch(actions.changeSelectedId(id)),
  };
};

export default connect(null, mapDispatchToProps)(Categories);
