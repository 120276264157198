import React from 'react';
import classes from "./ColorPicker.module.scss";
import {MdClose} from "react-icons/md";
import {ChromePicker} from "react-color";

const ColorPicker = (props) => {
  return (
    <div className={classes.ColorPickerPopup}>
      <div className={classes.CloseButtonContainer}>
        <div onClick={props.onClose}>
          <MdClose color="#C5C5C5" size={20} />
        </div>
      </div>
      <ChromePicker
        onChange={(e) => {
          props.changeColor(e.hex)
        }}
        className={classes.ColorPicker}
        color={props.color}
      />
      <button
        style={{background:props.color}}
        className={classes.SaveButton}
        onClick={props.onClose}
      >
        Save
      </button>
    </div>
  );
}

export default ColorPicker;
