import classes from './Wrapper.module.scss';

const Wrapper = ({children, sideBarOpen}) => {
  return (
    <div
      className={sideBarOpen ? classes.SidebarOpen : classes.SidebarClose}
    >
      {children}
    </div>
  );
};

export default Wrapper;
