import React from 'react';
import classes from './Backdrop.module.scss';

const Backdrop = ({onClick, children}) => {
  return (
    <div className={classes.BackdropContainer}>
      <div className={classes.Backdrop} onClick={onClick}></div>
      {children}
    </div>
  );
};

export default Backdrop;
