import axios from '../../../axios';
import * as actionTypes from './actionTypes';
import {signOut} from "../auth/auth";

const updateAccountStart = () => {
  return {
    type: actionTypes.UPDATE_ACCOUNT_DETAILS_START,
  };
};

const updateAccountEnd = () => {
  return {
    type: actionTypes.UPDATE_ACCOUNT_DETAILS_END
  };
};

export const getAccountDetails = () => {
  return async dispatch => {
    try {
      const response = await axios.get('api/user-service/get-account-detail')
      return response.data;
    }catch (err) {
      console.log('error : ' + err)
      throw err;
    }
  };
};

export const updateAccountDetails = (accountDetails,showToast,logoutStatus) => {
  return dispatch => {
    dispatch(updateAccountStart());
    axios
      .post('api/user-service/update-account-detail', accountDetails)
      .then((res) => {
        if (res.status === 200) {
          dispatch(updateAccountEnd());
          showToast('Account Update Successfully!');
          if (!logoutStatus) {
            setTimeout(() => {
              dispatch(signOut());
            }, 1500);
          }
        }
      })
      .catch((e) => {
        if (e.response.data.errorCode === 'USERNAME_ALREADY_EXISTS'){
          dispatch(updateAccountEnd())
          showToast('Username already exists!');
          return false;
        }
        showToast('Something went wrong!');
      });
  };
};

export const changePassword = (oldPassword, newPassword,showToast) => {
  return dispatch => {
    axios
      .post('/api/user-service/change-password',{newPassword, oldPassword})
      .then(response => {
        showToast("Successfully changed password!")
      })
      .catch((e) => {
        if(e.response.data.errorCode === 'INVALID_PASSWORD'){
          showToast("Invalid Old Password")
        }else{
          showToast("Something went wrong!")
        }
      })
  }
}

