import React from 'react';
import classes from './DeleteConfirmation.module.scss';
import {MdOutlineClose} from 'react-icons/md';
import {createGradient} from "../../../shared/Constants";

const DeleteConfirmation = props => {
  return (
    <div className={classes.DeleteConfirmation}>
      <div className={classes.CloseButtonContainer}>
        <div onClick={props.onClose}>
          <MdOutlineClose size={22} color="#C5C5C5" />
        </div>
      </div>
      <p className={classes.ConfirmationText}>Delete profile?</p>
      <p className={classes.InfoText}>
        This profile will be unavailable on your profile list
      </p>
      <button
        style={{background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color,50)} 100%)`}}
        className={classes.ContinueButton}
        onClick={props.onConfirmation}>
        Yes, Delete
      </button>
    </div>
  );
};

export default DeleteConfirmation;
