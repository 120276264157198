import Div100vh from "react-div-100vh";
import classes from "./ForgotPasswordPage.module.scss";
import React from "react";
import ForgotPasswordHeader from "../../components/Auth/ForgotPassword/ForgotPasswordHeader/ForgotPasswordHeader";
import ForgotPasswordBody from "../../components/Auth/ForgotPassword/ForgotPasswordBody/ForgotPasswordBody";

const ForgotPasswordPage = () => {
  return (
    <Div100vh className={classes.ForgotPasswordPage}>
      <ForgotPasswordHeader/>
      <ForgotPasswordBody/>
    </Div100vh>
  );
};

export default ForgotPasswordPage;
