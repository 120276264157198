export const CLEAR_DATA = 'CLEAR_DATA';

export const ADD_PROFILE_FIELD = 'ADD_PROFILE_FIELD';
export const SAVE_PROFILE_PICTURE_SUCCESS = 'SAVE_PROFILE_PICTURE_SUCCESS';
export const CHANGE_PROFILE_PICTURE = 'CHANGE_PROFILE_PICTURE';
export const CHANGE_PROFILE_NAME = 'CHANGE_PROFILE_NAME';
export const CHANGE_LEFT_PROFILE_FIELD = 'CHANGE_LEFT_PROFILE_FIELD';
export const CHANGE_RIGHT_PROFIE_FIELD = 'CHANGE_RIGHT_PROFIE_FIELD';
export const CHANGE_ADDITIONAL_PROFILE_FIELD =
  'CHANGE_ADDITIONAL_PROFILE_FIELD';

export const REMOVE_ADDITIONAL_PROFILE_FIELD =
  'REMOVE_ADDITIONAL_PROFILE_FIELD';

export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';

export const CHANGE_DARK_MODE = 'CHANGE_DARK_MODE';
